import './404.css';

export default function P404() {
  return (
    <>
      <div className="container404">
        <img src="/img/404_img.png" alt="🤨" />
        <p>Pero para donde te vas mi king? 👑</p>
        <a href="/">Regresar</a>
      </div>
    </>
  );
}
