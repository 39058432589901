import './descripcion.css';

export default function Descripcion() {
    return (
        <section className="description">
        <p className="p">
          Me llamo Carlos, tengo 18, estudiante de la Universidad César Vallejo,
          y estoy empezando con el Desarrollo Web. Actualmente estudiando en un
          curso de JavaScript y vivo en Perú 🇵🇪
        </p>
      </section>
    );
}